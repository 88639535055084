import React, { useEffect, useState } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { Global, css } from '@emotion/react';
import * as normalize from '../styles/normalize.css';
import * as global from '../styles/global.css';
import PortableText from '../components/portable-text';
import SEO from '../components/seo';
import Search from '../components/search';
import Close from '../svg/close-circle.svg';
import ScrollTop from '../svg/scroll-top.svg';

export default function Layout(props) {
  const query = graphql`
    query LayoutQuery {
      teacherPages: allSanityTeacherPage(sort: {fields: title, order: ASC}) {
        nodes {
          title
          slug {
            current
          }
        }
      }
      studentPages: allSanityStudentPage(sort: {fields: title, order: ASC}) {
        nodes {
          title
          slug {
            current
          }
        }
      }
      footer: sanityFooterSettings(_id: { regex: "/(drafts\\\\.)?footerSettings/" }) {
        _rawInformation(resolveReferences: {maxDepth: 10})
        _rawSubscription(resolveReferences: {maxDepth: 10})
        _rawSocials(resolveReferences: {maxDepth: 10})
        _rawSupport(resolveReferences: {maxDepth: 10})
        _rawOnlineSupport(resolveReferences: {maxDepth: 10})
      }
    }
  `

  const [menuAbsolute, setMenuAbsolute] = useState(false);
  const [activePage, setActivePage] = useState(null);
  const [activeSubPage, setActiveSubPage] = useState(null);
  const [activeLearnCategory, setActiveLearnCategory] = useState(null);
  const [showExtra, setShowExtra] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showLearn, setShowLearn] = useState(false);
  const [showTeachers, setShowTeachers] = useState(false);
  const [showStudents, setShowStudents] = useState(false);
  const [imagesModal, setImagesModal] = useState(false);
  const [embed, setEmbed] = useState(null);
  const [programLocation, setProgramLocation] = useState('all');
  const [time, setTime] = useState('now');
  const [filter, setFilter] = useState(null);
  const [typeFilters, setTypeFilters] = useState([]);
  const [seriesFilters, setSeriesFilters] = useState([]);
  const [showUp, setShowUp] = useState(false);

  const children = React.Children.map(props.children, child => {
    return React.cloneElement(child, {
      setMenuAbsolute: setMenuAbsolute,
      setActivePage: setActivePage,
      setActiveSubPage: setActiveSubPage,
      setShowLearn: setShowLearn,
      setShowExtra: setShowExtra,
      showImages: showImages,
      setShowImages: setShowImages,
      setShowInfo: setShowInfo,
      showInfo: showInfo,
      setShowStudents: setShowStudents,
      setShowTeachers: setShowTeachers,
      setImagesModal: setImagesModal,
      setEmbed: setEmbed,
      programLocation: programLocation,
      setProgramLocation: setProgramLocation,
      time: time,
      setTime: setTime,
      filter: filter,
      setFilter: setFilter,
      typeFilters: typeFilters,
      setTypeFilters: setTypeFilters,
      seriesFilters: seriesFilters,
      setSeriesFilters: setSeriesFilters,
    });
  });

  useEffect(() => {
    if (window) {
      let lastScroll = window.pageYOffset;

      window.addEventListener('scroll', (e) => {
        if (
          (window.pageYOffset > window.innerHeight && window.pageYOffset < lastScroll) ||
          (window.pageYOffset > (document.body.clientHeight - (window.innerHeight * 2)))
        ) {
          setShowUp(true);
        } else {
          setShowUp(false);
        }
        
        lastScroll = window.pageYOffset;
      });
    }
  }, []);

  return (
    <StaticQuery
      query={query}
      render={data => {
        return (
          <div
            css={css`
              margin: 0 auto;
              padding: var(--smallGutter);
            `}
          >
            <Global
              styles={css`
                ${normalize}
                ${global}

                html {
                  overflow: ${((showImages && imagesModal) || embed) ? 'hidden' : 'visible'};
                }
              `}
            />
            <SEO />
            {embed && <div
              css={css`
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: var(--darkBrown);
                z-index: 102;
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: absolute;
                  top: var(--smallGutter);
                  right: calc(var(--smallGutter) + 11.8rem);
                  bottom: var(--smallGutter);
                  left: var(--smallGutter);
                  background-color: var(--white);

                  @media (max-width: 900px) {
                    right: calc(var(--smallGutter) + 7.5rem);
                  }

                  @media (max-width: 700px) {
                    right: 11.4rem;
                  }

                  iframe {
                    width: 100%;
                    height: 100%;
                    border: 0;
                  }
                `}
                dangerouslySetInnerHTML={{__html: embed}}
              ></div>
              <div
                css={css`
                  position: absolute;
                  top: var(--smallGutter);
                  right: var(--smallGutter);
                  line-height: 0;
                  cursor: pointer;

                  &:hover {
                    opacity: 0.6;
                  }

                  svg {
                    path {
                      fill: var(--black);
                    }
                  }

                  @media (max-width: 1440px) {
                    top: calc(var(--smallGutter) - 2.2rem);
                    transform-origin: 100% 100%;
                    transform: scale(0.8);
                  }

                  @media (max-width: 900px) {
                    top: -0.4rem;
                    right: -1.5rem;
                    transform-origin: 50% 50%;
                    transform: scale(0.4);
                  }
                `}
                role="button"
                tabIndex="0"
                onClick={(e) => setEmbed(null)}
                onKeyPress={(e) => {
                  e.persist();

                  if (e.key === 'Enter') {
                    setEmbed(null);
                  }
                }}
              >
                <Close />
              </div>
            </div>}
            <header
              className="type--large"
              css={css`
                position: ${(menuAbsolute) ? 'absolute' : 'static'};
                top: var(--smallGutter);
                right: var(--smallGutter);
                left: var(--smallGutter);
                z-index: 1;

                a, [role=button] {
                  display: inline;
                  color: ${(menuAbsolute) ? 'var(--white)' : 'invalid'};
                  text-shadow: ${(menuAbsolute) ? '0 0 15px rgba(0, 0, 0, 0.15)' : 'none'};
                  text-decoration: none;
                  background-color: transparent;
                  border: none;
                  cursor: pointer;

                  &:hover {
                    color: var(--pink);
                  }
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;

                  @media (max-width: 1100px) {
                    display: block;
                  }
                `}
              >
                <div>
                  <div
                    css={css`
                      @media (max-width: 1100px) {
                        position: relative;
                        z-index: 101;
                      }
                    `}
                  >
                    <Link to={`/`}>
                      BLIND<span css={css`display: inline-block; transform: scale(-1, -1) translateY(-1.25rem); @media (max-width: 700px) { transform: scale(-1, -1) translateY(-1rem); }`}>SIDE</span>,
                    </Link>{' '}
                    <Link
                      css={css`
                        color: ${(activePage !== 'program') ? 'var(--20Brown)' : 'var(--black)'};
                      `}
                      to={`/program/`}
                    >
                      Program,
                    </Link>{' '}
                    <Link
                      css={css`
                        color: ${(activePage === 'info' || showInfo) ? 'var(--black)' : 'var(--20Brown)'};
                      `}
                      to={`/about/`}
                    >
                      Info
                    </Link>
                  </div>
                </div>
                <div
                  css={css`
                    position: relative;
                    display: ${(showExtra) ? `block` : `none`};
                    text-align: right;
                    z-index: 101;

                    @media (max-width: 1100px) {
                      display: ${((activePage === 'info' || showInfo) || !showExtra) ? 'none' : 'block'};
                      text-align: left;
                      margin-bottom: var(--gutter);
                    }

                    span {
                      color: var(--20Brown);
                      cursor: pointer;
                      user-select: none;

                      &[aria-pressed="true"] {
                        color: var(--black);
                      }
                    }
                  `}
                >
                  <span
                    tabIndex="0"
                    role="button"
                    aria-pressed={!showImages}
                    onMouseDown={() => setShowImages(false)}
                    onKeyPress={(e) => { if (e.key === 'Enter') setShowImages(false) }}
                  >Overview,</span>{' '}
                  <span
                    tabIndex="0"
                    role="button"
                    aria-pressed={showImages}
                    onMouseDown={() => setShowImages(true)}
                    onKeyPress={(e) => { if (e.key === 'Enter') setShowImages(true) }}
                  >Images</span>
                </div>
              </div>
              <div>
                <div
                  css={css`
                    display: ${(showInfo) ? 'block' : 'none'};
                  `}
                >
                  <Link
                    css={css`
                      color: ${(activeSubPage !== 'about') ? 'var(--20Brown)' : 'var(--black)'};
                    `}
                    to={`/about`}
                  >
                    About,
                  </Link>{' '}
                  <Link
                    css={css`
                      color: ${(activeSubPage !== 'opportunities') ? 'var(--20Brown)' : 'var(--black)'};
                    `}
                    to={`/opportunities`}
                  >
                    Opportunities,
                  </Link>{' '}
                  <div
                    role="button"
                    css={css`
                      color: ${(showLearn) ? 'var(--black)' : 'var(--20Brown)'};
                    `}
                    onClick={() => {
                      setShowLearn(!showLearn);
                      setShowTeachers(false);
                      setShowStudents(false);
                    }}
                  >
                    Learn,
                  </div>{' '}
                  <Link
                    css={css`
                      color: ${(activeSubPage !== 'support') ? 'var(--20Brown)' : 'var(--black)'};
                    `}
                    to={`/support`}
                  >
                    Support,
                  </Link>{' '}
                  <Link
                    css={css`
                      color: ${(activeSubPage !== 'visit') ? 'var(--20Brown)' : 'var(--black)'};
                    `}
                    to={`/visit`}
                  >
                    Visit
                  </Link>
                </div>
                <div
                  css={css`
                    display: ${(showInfo && showLearn) ? 'block' : 'none'};
                  `}
                >
                  <Link
                    css={css`
                      color: ${(showTeachers) ? 'var(--black)' : 'var(--20Brown)'};
                    `}
                    to={`/teachers/${data.teacherPages?.nodes[0]?.slug?.current}`}
                    onClick={() => {
                      setShowStudents(false);
                    }}
                  >
                    For Teachers,
                  </Link>{' '}
                  <Link
                    css={css`
                      color: ${(showStudents) ? 'var(--black)' : 'var(--20Brown)'};
                    `}
                    to={`/students/${data.studentPages?.nodes[0]?.slug?.current}`}
                    onClick={() => {
                      setShowTeachers(false);
                    }}
                  >
                    For Students
                  </Link>
                </div>
                <div
                  className="type--medium"
                  css={css`
                    display: ${(showInfo && showTeachers) ? 'block' : 'none'};
                    margin-top: 2rem;
                  `}
                >
                  {data.teacherPages?.nodes.length > 0 && data.teacherPages.nodes
                    .map((item, i) => (
                      <Link
                        key={i}
                        css={css`
                          color: ${(activeSubPage !== item.slug.current) ? 'var(--20Brown)' : 'var(--black)'};
                        `}
                        to={`/teachers/${item.slug.current}`}
                      >
                        {item.title}{(i < data.teacherPages.nodes.length - 1) && ','}
                      </Link>
                    ))
                    .reduce((prev, curr) => [prev, ' ', curr])
                  }
                </div>
                <div
                  className="type--medium"
                  css={css`
                    display: ${(showInfo && showStudents) ? 'block' : 'none'};
                    margin-top: 2rem;
                  `}
                >
                  {data.studentPages?.nodes.length > 0 && data.studentPages.nodes
                    .map((item, i) => (
                      <Link
                        key={i}
                        css={css`
                          color: ${(activeSubPage !== item.slug.current) ? 'var(--20Brown)' : 'var(--black)'};
                        `}
                        to={`/students/${item.slug.current}`}
                      >
                        {item.title}{(i < data.studentPages.nodes.length - 1) && ','}
                      </Link>
                    ))
                    .reduce((prev, curr) => [prev, ' ', curr])
                  }
                </div>
              </div>
            </header>
            <main>
              {children}
              <div
                css={css`
                  position: sticky;
                  bottom: var(--smallGutter);
                  width: 87px;
                  margin-left: auto;
                  opacity: ${(showUp) ? 1 : 0};
                  cursor: pointer;

                  &:hover {
                    circle {
                      fill: var(--pink);
                    }
                  }

                  svg {
                    circle {
                      fill: var(--oldBrown);
                    }

                    path {
                      stroke: var(--white);
                    }
                  }

                  @media (max-width: 1440px) {
                    transform-origin: 100% 100%;
                    transform: scale(0.8);
                  }

                  @media (max-width: 700px) {
                    transform: scale(0.5);
                  }
                `}
                role="button"
                tabIndex="0"
                onClick={(e) => {
                  window.scrollTo(0, 0);
                  document.querySelector('header a').focus();
                }}
                onKeyPress={(e) => {
                  e.persist();

                  if (e.key === 'Enter') {
                    window.scrollTo(0, 0);
                    document.querySelector('header a').focus();
                  }
                }}
              >
                <ScrollTop />
              </div>
            </main>
            <footer
              className="type--small"
              css={css`
                display: grid;
                grid-template-columns: repeat(12, 1fr);
                gap: var(--gutter);
                margin-top: 5.7rem;

                p {
                  margin-bottom: 2rem;
                }
              `}
            >
              <Search />
              <div
                className="type--small"
                css={css`
                  grid-column: span 4;
                  margin-top: -2.1rem;

                  @media (max-width: 800px) {
                    grid-column: span 6;
                  }
                `}
              >
                <PortableText
                  blocks={data.footer?._rawInformation}
                  setEmbed={setEmbed}
                />
              </div>
              <div
                className="type--small"
                css={css`
                  grid-column: span 4;
                  margin-top: -2.1rem;

                  @media (max-width: 800px) {
                    grid-column: span 6;
                  }
                `}
              >
                <PortableText
                  blocks={data.footer?._rawSubscription}
                  setEmbed={setEmbed}
                />
                <form
                  css={css`
                    position: relative;
                    margin-bottom: 1rem;
                  `}
                  target="_blank"
                  action="https://blindside.us2.list-manage.com/subscribe/post?u=9c9ccb60da895b0caf76a5b64&id=68fccc2582"
                  method="POST"
                >
                  <input
                    css={css`
                      width: 100%;
                      background-color: transparent;
                      padding: 1.4rem 2rem 1.2rem;
                      border: 1px solid var(--black);
                      border-radius: 2.6rem;
                      margin-bottom: 2rem;
                    `}
                    type="email"
                    name="EMAIL"
                    placeholder="hello@example.com"
                  />
                  <input
                    css={css`
                      position: absolute;
                      top: 1.25rem;
                      right: 1rem;
                      font-size: 2rem;
                      background-color: transparent;
                      border: none;
                      cursor: pointer;
                    `}
                    type="submit"
                    value="→"
                  />
                </form>
                <PortableText
                  blocks={data.footer?._rawSocials}
                  setEmbed={setEmbed}
                />
              </div>
              <div
                className="type--small"
                css={css`
                  grid-column: span 4;
                  margin-top: -2.1rem;

                  @media (max-width: 800px) {
                    grid-column: span 12;
                    padding-bottom: 10rem;
                  }
                `}
              >
                <PortableText
                  css={css`
                    display: var(--offline);
                  `}
                  blocks={data.footer?._rawSupport}
                  setEmbed={setEmbed}
                />
                <PortableText
                  css={css`
                    display: var(--online);
                  `}
                  blocks={data.footer?._rawOnlineSupport}
                  setEmbed={setEmbed}
                />
              </div>
            </footer>
          </div>
        )}
      }
    />
  )
}
